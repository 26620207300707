import store from 'src/store'

import { updatePianoSize }                                               from 'src/misc/ViewPortVars'
import { MODULES_TO_PERSIST, MUTATION_TYPES_TO_TRIGGER_SAVE, RANGE_END } from 'src/misc/app-constants'
import { setupDrums, setupPlayer }                                      from 'framework/helpers/app-initialization'
import { loadModules, persistModules }                                   from 'framework/helpers/persist-vuex-state'

export async function setupApp() {
  await setupPlayer()
  await setupDrums()
  await setupAppState()
  setupVuexStateKeeper()
  setupPiano()
}

function setupPiano() {
  updatePianoSize()
  window.addEventListener('resize', updatePianoSize)
}

async function setupAppState() {
  await store.dispatch('context/setPitchClass', { key: RANGE_END, pitchClass: 'C' })
  await store.dispatch('context/setOctave', { key: RANGE_END, octave: 4 })
}

function setupVuexStateKeeper() {
  store.subscribe((mutation) => {
    if(MUTATION_TYPES_TO_TRIGGER_SAVE.includes(mutation.type)) {
      persistModules(MODULES_TO_PERSIST)
    }
  })

  loadModules(MODULES_TO_PERSIST)
}
