import store                            from 'src/store'
import { DRUMS_PLAYER, DEFAULT_PLAYER } from 'framework/resources/framework-constants'
import { localStore }                   from 'framework/helpers/LocalStorageHandler'
import audioManager                     from 'framework/models/AudioManager'

/**
 * Setup the default app player from memory.
 * @returns {Promise<void>}
 */
export async function setupPlayer() {
  const savedLead = await localStore.get('instrument')
  const name      = savedLead || 'piano'
  await store.dispatch('players/setPlayerInstrument', {
    id:    DEFAULT_PLAYER,
    value: name
  })
  store.commit('players/SET_INSTRUMENTS_READY')
}

/**
 * initialize all instruments from the store.
 * For apps with multiple defined players that work simultaneously.
 * @returns {Promise<void>}
 */
export async function setupPlayersFromStore() {
  Object.values(store.state.players.players).forEach(({ instrument }) => audioManager.add(instrument))

  store.commit('players/SET_INSTRUMENTS_READY')
}

/**
 * setup app drums player.
 * @returns {Promise<void>}
 */
export async function setupDrums() {
  await audioManager.add(DRUMS_PLAYER)
}

