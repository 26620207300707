import store from 'src/store'

export function updatePianoSize() {
  store.dispatch('settings/setPianoKeySizeMultiplier', getPianoKeySizeMultiplier())
}

function getPianoKeySizeMultiplier() {
  const width = window.innerWidth
  if(width <= 360) {
    return 4
  } else if(width < 445) {
    return 4.5
  } else if(width < 530) {
    return 5
  } else if(width < 700) {
    return 5
  } else if(width < 768) {
    return 3
  } else if(width < 1024) {
    return 3
  } else {
    return 3.5
  }
}
