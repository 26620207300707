import store          from 'src/store'
import { localStore } from 'framework/helpers/LocalStorageHandler'

async function persistModule(moduleName) {
  try {
    await localStore.set(moduleName, store.state[moduleName])
  } catch (e) {
    // @todo add error handling
  }
}

export function persistModules(moduleNames) {
  moduleNames.forEach(module => persistModule(module))
}

async function loadModule(moduleName) {
  try {
    const moduleState = await localStore.get(moduleName)
    store.commit('LOAD_MODULE', { moduleName, moduleState })
  } catch (e) {
    // @todo
  }
}

export function loadModules(moduleNames) {
  moduleNames.forEach(module => loadModule(module))
}